import React from 'react';
import ImageUtil from '../../util/ImageUtil';
import { FrontendImage } from '../../models/FrontendImage';

const ResponsiveImage: React.FC<{ img?: Partial<FrontendImage> }> = ({
    img,
}) => {
    if (!img) return null;

    const image = ImageUtil.getImage(img);

    const { imageUrl, imageSet } = image;

    if (!imageUrl || !imageSet) return null;

    return (
        <div className="a-image" data-t-name="Image" data-t-id="17">
            <figure>
                <img
                    className="img-responsive"
                    src={imageUrl}
                    srcSet={imageSet}
                    alt=""
                />
            </figure>
        </div>
    );
};

export default ResponsiveImage;
